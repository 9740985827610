import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';


// Home Pages Import
// import DigitalAgency from './pages/DigitalAgency';
// import BlogGridView from './pages/BlogGrid';
// import BlogDetails from './pages/BlogDetails';
// import BlogCategory from './pages/Category';
// import BlogArchive from './pages/Archive';
import ComingSoon from './pages/ComingSoon';




// Css Import
import './assets/scss/app.scss';


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<ComingSoon />}/>
				{/* <Route path={process.env.PUBLIC_URL + "/"} element={<DigitalAgency />}/>
				<Route path={process.env.PUBLIC_URL + "/blogs"} element={<BlogGridView />}/>
				<Route path={process.env.PUBLIC_URL + "/blog-details/:slug"} element={<BlogDetails />}/>
				<Route path={process.env.PUBLIC_URL + "/category/:slug"} element={<BlogCategory />}/>
				<Route path={process.env.PUBLIC_URL + "/archive/:slug"} element={<BlogArchive />}/> */}

			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
